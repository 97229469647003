.faq {
    font-family: sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    /* width: 100%; */
}

.question-section {
    background: transparent;
    border: 1px solid lightgray;
    border-radius: 8px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
        rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;
    width: 92vw;
}

.question-align {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
    min-height: 10px;
    text-align: left;
}

.question-align h4 {
    margin-left: 8px;
}

.question-style {
    color: #5b5b5b;
    /* margin-left: 8px;
    margin-right: 8px; */

}

/* .question-style h4 {
    color: #5b5b5b;
    margin-left: 8px;
    margin-right: 8px;
} */

.question-icon {
    background: none;
    border: none;
    color: #1b6aae;
    cursor: pointer;
    font-size: 22px !important;
    margin-left: auto;
    margin-right: 8px;
}

.rotate {
    transform: rotate(45deg);
}

.answer-divider {
    border-top: 1px solid lightgray;
    /* margin-top: 10px; */
}

.answer {
    /* margin-top: 15px; */
    margin-left: 8px;
    margin-right: 8px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease;
    text-align: left;
}

/* .MuiContainer-root {
    padding-left: 0px;
} */

/* button {
    width: 100%;
} */